$box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  // - BREAKPOINTS
$bp-mobile: 20em; // 320px
$bp-mobile-larger: 23.4375em; // 375px
$bp-small: 48em; // 768px
$bp-medium: 64em; // 1024px
$bp-ipad-air: 73.75em;
$bp-large: 85.375em; // 1366px
$bp-xlarge: 120em; // 1920px
$bp-2k: 159em; // 2544px
$bp-4k: 239em; // 3824px

$multiplier-4k: 1.7;
$multiplier-2k: 1.2;

// - MEDIA QUERIES
$mq-mobile: "(min-width: #{$bp-mobile})";
$mq-mobile-larger: "(min-width: #{$bp-mobile-larger})";

$mq-small: "(min-width: #{$bp-small})";
$mq-small-portrait: "(min-width: #{$bp-small}) and (orientation: portrait)";
$mq-small-landscape: "(min-width: #{$bp-small}) and (orientation: landscape)";

$mq-medium: "(min-width: #{$bp-medium})";
$mq-medium-portrait: "(min-width: #{$bp-medium}) and (orientation: portrait)";
$mq-medium-landscape: "(min-width: #{$bp-medium}) and (orientation: landscape)";

$mq-ipad-air: "(min-width: #{$bp-ipad-air})";

$mq-large: "(min-width: #{$bp-large})";
$mq-xlarge: "(min-width: #{$bp-xlarge})";
$mq-2k: "(min-width: #{$bp-2k})";
$mq-4k: "(min-width: #{$bp-4k})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
