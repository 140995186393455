.bannerContainer {
  .igx-banner {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em;

    .igx-banner__message {

    }

    .igx-banner__actions {
      position: relative;
      top: -2.5em;
    }
  }

  .buttonContainer {
    display: flex;
    margin-top: 1em;

    .bannerButton {
      margin-right: 1em;
    }
  }

}
