mat-vertical-stepper {
  overflow: hidden !important;

  form {
    display: flex;
    flex-flow: column wrap;

    .inputBundler {
      display: flex;
      flex-direction: row;
      width: 100%;

      mat-form-field, mat-radio-group {
        flex-grow: 1;
      }
    }

    .inputBundler > mat-form-field, .radioGroupBundler {
      margin-right: 2em;
    }

    .inputBundler > mat-form-field:last-child, .radioGroupBundler:last-child {
      margin-right: 0;
    }

    .smallItem {
      flex-grow: unset !important;
      flex-shrink: 0;
    }

    .radioGroupBundler {
      display: inline-flex;

      label {
        margin-right: 1em;
      }

      mat-radio-group {
        display: flex;
        flex-direction: column;

        .mat-radio-button {
          margin-bottom: .2em;
        }
      }
    }

    h2 {
      font-weight: normal;
    }

    .appendixContainer {
      display: flex;

      .appendixBox {
        position: relative;
        margin-left: 1em;

        .scrim {
          background: rgba(0, 0, 0, .7);
          display: none;
          width: 100%;
          height: 100%;
          position: absolute;

          mat-icon {
            font-size: 4.5em;
            position: absolute;
            left: 35.25%;
            right: 34.99%;
            top: 28.85%;
            bottom: 26.39%;
          }
        }


        .appendixText {
          margin: 0 0 1em 1em;

          h4 {
            margin-bottom: 0;
          }
        }
      }

      .appendixBox:first-child {
        margin-left: 0;
      }

      .appendixBox:hover {
        cursor: pointer;

        .scrim {

          display: block;

        }

      }
    }

    .columnGroup {
      display: flex;
      flex-direction: column;
    }

  }
}

.mat-dialog-container mat-card .dialog-content-container mat-horizontal-stepper {

  form {
    margin-bottom: 2em;
  }

  .documentsform {
    margin-bottom: 0;
  }


  .stepperControls {
    right: 2em;
  }
}

.stepperControls {
  margin: 1em 0 0;
  display: flex;
  justify-content: flex-end;
}
