// CSS Reset, comment out if not required or using a different module
//@import '~minireset.css/minireset';

//@import "~igniteui-angular/lib/core/styles/themes/index";
//@include igx-theme($default-palette);

/* You can add global styles to this file, and also import other style files */
@import './styles/theme';
@import "src/styles/_theme/theme";
@import "~mdb-angular-ui-kit/assets/scss/mdb.scss";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'TTProstoSans-Regular', sans-serif;
  $font-family-base: 'TTProstoSans-Regular', sans-serif;
}

button.mat-menu-item {
  line-height: 24px !important;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.mat-icon svg {
  height: 24px;
  width: 24px;
}

.retrievingInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  height: 10rem;

  ::ng-deep {
    .mat-progress-spinner circle, .mat-spinner circle {
      stroke: var(--dashboard-progress-bar-percentage-color);
    }
  }
}

@import "styles/global";
