.inputBundler {
  display: flex;
  flex-direction: row;
  width: 100%;

  mat-form-field, mat-radio-group, app-dropdown {
    flex-grow: 1;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding-left: 0;
  }

  .mat-form-field-type-mat-select {
    .mat-form-field-infix {

      padding-bottom: 0;
    }
  }

  .checkboxBundler {
    width: 98%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }

}

form {
  display: flex;
  flex-flow: column wrap;

  .inputBundler {
    display: flex;
    flex-direction: row;
    width: 100%;

    mat-form-field, mat-radio-group {
      flex-grow: 1;
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
      padding-left: 0;
    }

    .mat-form-field-type-mat-select {
      .mat-form-field-infix {

        padding-bottom: 0;
      }
    }

    .checkboxBundler {
      width: 98%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1em;
    }

  }

  .inputBundler > mat-form-field, .radioGroupBundler {
    margin-right: 2em;
  }

  .inputBundler > mat-form-field:last-child, .radioGroupBundler:last-child {
    margin-right: 0;
  }

  .smallItem {
    flex-grow: unset !important;
    flex-shrink: 0;
  }

  .radioGroupBundler {
    display: inline-flex;

    label {
      margin-right: 1em;
    }

    mat-radio-group {
      display: flex;
      flex-direction: column;

      .mat-radio-button {
        margin-bottom: .2em;
      }
    }
  }

  .columnGroup {
    display: flex;
    flex-direction: column;
  }

  .rowGroup {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    margin-bottom: 1em;
  }


  .mat-input-element:disabled {
    cursor: not-allowed;
  }
}


.prospectDialog, .editStudyTrackDialog, .detailContainer, .mat-stepper-horizontal {
  mat-form-field {
    line-height: 1.5em;

    .mat-select-value {
      padding: .2em;
    }
  }

  mat-checkbox {
    display: flex;
    align-items: center;
    margin-right: 1em;
  }
}
