app-filter {
  display: flex;
}

.filterAndSearchArea {
  display: flex;
  justify-content: flex-end;
  margin-right: -1em;

  .searchBox {
    position: absolute;
    left: 0;
    width: 460px !important;
    max-width: unset !important;
  }

  mat-card.filterBox.mat-card, .searchBox {
    max-width: 230px;
    margin: 1em;
    padding-top: 0.5rem;
    padding-bottom: 0;
    //box-shadow: 0px 6px 0px #1565C0;
    border-radius: 10px;

    mat-card-content {
      padding: 1em 1em 0;

      mat-form-field {
        width: 100%;
      }
    }
  }
}

.dateProposalContainer .dateProposal, .selectedDateProposal {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: 10em;
  height: 2.6em;
  border-radius: 5px 10px 10px 5px;

  .dateProposalSidebar {
    border-radius: 10px 0px 0px 10px;
  }
  .dateProposalText {
    width: 8em;
    position: absolute;
    left: 3em;
    line-height: .7em;
    margin-top: .4em;
    h5 {
      margin: 0;
      font-weight: normal;
    }
    sub {
      opacity: .54;
    }
  }

  mat-icon {
    position: absolute;
    left: 6em;
    margin-top: .4em;
  }
}


.dateProposalContainer > .dateProposal:first-child {
  margin-top: 0;
}
