mat-tab-group {
  border-radius: 15px;
  box-shadow: $box-shadow;
  margin-right: 0;


  mat-tab-header {
    border-radius: 15px 15px 0px 0px;
    font-size: 2em;

    .mat-tab-labels {
      justify-content: space-evenly;
    }

    .mat-tab-label {
      font-size: .75em;
      padding: 1.06em;
    }

    .mat-tab-label.mat-tab-label-active {
      opacity: unset;
      font-weight: bold;
    }

    mat-ink-bar.mat-ink-bar {
      height: .1em;
    }
  }

  .mat-tab-body-wrapper {
    height: 100%;

    mat-tab-body {
      border-radius: 0 0 15px 15px;
      padding: 2em;

      form {
        width: 97%;
      }
    }
  }


}
