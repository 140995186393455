.mat-table {
  width: 100%;
  border-radius: 0px 20px 5px 0px;
  box-shadow: unset;

  span {
    display: flex;
    border-radius: 30px;
    justify-content: center;
    width: fit-content;
    //display: block;
    //height: 32px;
    //width: 32px;
    //line-height: 36px;
    //-moz-border-radius: 30px;
    //border-radius: 30px;
    //text-align: center;
    //font-size: 2em;
    p {
      margin: 0 2em;
      font-size: smaller;
    }
  }

  .hoverableRow:hover {
    cursor: pointer;
  }

  span:hover {
    cursor: default;
  }

}

.statusIndicator {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
}
