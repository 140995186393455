.appendixContainer {
  display: flex;
  align-items: center;

  .appendixBox {
    position: relative;
    margin-left: 1em;

    .scrim {
      background: rgba(0, 0, 0, .7);
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;

      mat-icon {
        font-size: 4.5em;
        position: absolute;
        left: 35.25%;
        right: 34.99%;
        top: 28.85%;
        bottom: 26.39%;
      }
    }


    .appendixText {
      margin: 0 0 1em 1em;

      h4 {
        margin-bottom: 0;
      }
    }
  }

  .imagePreview {
    width: 13em;
    height: 13em;

    mat-icon {
      font-size: 12.5em;
    }
  }

  .appendixBox:first-child {
    margin-left: 0;
  }

  .appendixBox:hover {
    cursor: pointer;

    .scrim {

      display: block;

    }

  }
}

.columnGroup {
  display: flex;
  flex-direction: column;
}
