.mat-dialog-container {
  padding: 0 !important;
  overflow: unset !important;
  background: #FFFFFF;
  color: #000000;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px !important;

  mat-card {
    padding: 0 !important;
    //height: 50vh;

    mat-card-header {

      mat-card-title {
        padding: .5em 0 0 0;
      }
    }

    .dialog-content-container {
      padding: 1em;

      mat-horizontal-stepper {
        form {
          display: flex;
          flex-flow: column wrap;

          .inputBundler {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            mat-form-field, mat-radio-group {
              flex-grow: 1;
            }
          }

          .motivationRadioGroupBundler {
            width: 100%;
          }

          .inputBundler > mat-form-field, .radioGroupBundler {
            margin-right: 2em;
          }

          .inputBundler > mat-form-field:last-child, .radioGroupBundler:last-child {
            margin-right: 0;
          }

          .smallItem {
            flex-grow: unset !important;
            flex-shrink: 0;
          }

          .radioGroupBundler {
            display: inline-flex;

            label {
              margin-right: 1em;
            }

            mat-radio-group {
              display: flex;
              flex-direction: column;

              .mat-radio-button {
                margin-bottom: .2em;
              }
            }
          }

        }

        .stepperControls {
          position: absolute;
          bottom: 1em;


        }
      }
    }

    mat-card-actions {
      margin: 1em;

      button {
        margin-bottom: 1em !important;
      }
    }
  }

  .mat-dialog-content {
    padding: 0 !important;
    margin: 0 !important;


  }

  .dialogActions {
    margin: 0;

    button {
      margin-bottom: 0 !important;
    }
  }

}

.close-button {
  position: absolute !important;
  top: .3em;
  right: 1em;
}
