mat-sidenav-content {
  .content-viewport {
    height: calc(100% - 5.5em);
    overflow-x: hidden;

    .cdk-virtual-scroll-content-wrapper {
      height: calc(100vh - 5.5em);
    }
  }
}

.container {
  padding-top: 5rem;

  .scrollingContainer {
    height: 100%;
    overflow-x: hidden;
  }

  .scrollingAccordionContainer {
    height: calc(100vh - 11.5em);
  }

  .scrollingContainer::-webkit-scrollbar {
    margin-left: 1em;
  }

  .overviewContainer {
    padding: 0 1em;
  }
}

//.mat-drawer {
//  box-shadow: 0px 5.5px 5px rgba(0, 0, 0, 0.24), 0px 9px 18px rgba(0, 0, 0, 0.18);
//}

.mat-drawer-side {
  border-right: none;
}

mat-sidenav-content::-webkit-scrollbar-thumb, cdk-virtual-scroll-viewport::-webkit-scrollbar, .mat-drawer-inner-container::-webkit-scrollbar {
  border-radius: 15px;
}

mat-sidenav-content::-webkit-scrollbar-thumb ,cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb, .mat-drawer-inner-container::-webkit-scrollbar-thumb {
  border-radius: 15px;
  height: 2em;
}

.timepicker-backdrop-overlay{
  z-index: 1000 !important;
}

.timepicker-overlay{
  z-index: 1000 !important;
}

// This fixes a bug when sorting programmatically: https://github.com/angular/components/issues/12754
.mat-header-cell .mat-sort-header-container.mat-sort-header-sorted .mat-sort-header-arrow
{
  opacity: 1 !important;
}

.quizProgressBar {
  height: .75rem !important;
  border: 1px solid #A55BA8;
  border-radius: 10px;

  .mat-progress-bar-fill::after {
    background-color: #EF8BFF;
  }

  .mat-progress-bar-buffer {
    background: #8474A1;
  }
}

.quizStar {
  background: no-repeat center url('../../../assets/svgs/star.svg');
  background-size: contain;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75rem;
}

.include-whitespace {
  white-space: pre-line;
}
